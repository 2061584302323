import Viewport from './_viewport';

/**
 * --------------------------------------------------------------------------
 * Emergency Management: _truncate.js
 * --------------------------------------------------------------------------
 */

const Truncate = (($) => {
	
    const $elements = $('.js-truncate');
    const options = {
		/*	The text to add as ellipsis. */
		ellipsis	: '... ',

		/*	How to cut off the text/html: 'word'/'letter'/'children' */
		wrap		: 'word',
 
		/*	Wrap-option fallback to 'letter' for long words */
		fallbackToLetter: true,
 
		/*	jQuery-selector for the element to keep and put after the ellipsis. */
		after		: null,
 
		/*	Whether to update the ellipsis: true/'window' */
		watch		: true,
	
		/*	Optionally set a max-height, if null, the height will be measured. */
		height		: null,
 
		/*	Deviation for the height-option. */
		tolerance	: 0,
 
		lastCharacter	: {
 
			/*	Remove these characters from the end of the truncated text. */
			remove		: [ ' ', ',', ';', '.', '!', '?' ],
 
			/*	Don't add an ellipsis if this array contains 
				the last character of the truncated text. */
			noEllipsis	: []
		}
	};

    return {
        init
    };

    /*-----------------*/

    function init () {
        $elements.each(function (_index, _element) {
			truncateText.call(_element);
		});
	}
	
	function truncateText () {
		var $this = $(this);

		// get line count from current breakpoint
		var lineCount = $this.data('lines-' + Viewport.getCurrentBreakPoint());
		var lineHeight = $this.css('line-height');
		
		// if not found, try generic line count attribute
		if (!lineCount) {
			lineCount = $this.data('lines');
		}

		// if not found, then just go home
		if (!lineCount) {
			return false;
		}

		// treat line height values
		if (lineHeight.toLowerCase().indexOf('px') > -1) {
			lineHeight = parseInt(lineHeight, 10);
		} else if (lineHeight.indexOf('%') > -1) {
			lineHeight = (parseInt(lineHeight, 10) / 100) * parseInt($this.css('font-size'), 10);
		} else {
			lineHeight = parseInt(lineHeight, 10) * parseInt($this.css('font-size'), 10);
		}

		// apply parameters
		options.height = (lineCount * lineHeight) + lineHeight / 3;
		$this.dotdotdot(options);

		// Fire isTruncated event and update heights
		$this.trigger("isTruncated", function () {
			$.fn.matchHeight._update();
		});
	}
    
})(jQuery);

export default Truncate;