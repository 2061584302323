import Config from './_config';

/**
 * --------------------------------------------------------------------------
 * Emergency Management: _carousel.js
 * --------------------------------------------------------------------------
 */

const Carousel = (($) => {

    const $carousels = $('.js-carousel'); 
    const options = {
        loop: false,
        margin: 0,
        nav: true,
        dots: false,
        navText: [
            '<img src="/assets/build/images/arrow-left.png">', 
            '<img src="/assets/build/images/arrow-right.png">'
        ],
        responsive: {
            0: {
                items: 3
            },
            450:{
                items: 4
            },            
            600:{
                items: 5
            },
            740:{
                items: 6
            },
            980:{
                items: 8
            }            
        }
    };

    return {
        init
    };

    /*-----------------*/

    function init () {
        $carousels.owlCarousel(options);
    }
    
})(jQuery);

export default Carousel;