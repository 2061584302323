import Config from './_config';

/**
 * --------------------------------------------------------------------------
 * Emergency Management: _modal.js
 * --------------------------------------------------------------------------
 */

const Modal = (($) => {

    const $popups = $('.js-popup-link'); 
    const options = {
        type:'image'
    };

    return {
        init
    };

    /*-----------------*/

    function init () {
        $popups.magnificPopup(options);
    }
    
})(jQuery);

export default Modal;