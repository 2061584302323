import Config from './_config';

/**
 * --------------------------------------------------------------------------
 * Emergency Management: _tabs.js
 * --------------------------------------------------------------------------
 * 
 * Dependencies: Bootstrap's 'tab.js' module
 * 
 */

const Tabs = (($) => {

    const $tabs = $('.js-tab'); 
    const $mobileDropdown = $('.js-tab-dropdown'); 

    return {
        init
    };

    /*-----------------*/

    function init () {
        $tabs.tab();
        setActiveTab($tabs);
        bindEvents();
    }
    
    function bindEvents () {
        $tabs.click(function(e) {
            e.preventDefault();
            show($(this));
        });
        $mobileDropdown.change(selectOption);    
    }

    function setActiveTab ($tabs) {
        if (!$tabs.length) { return; }

        if (!$tabs.hasClass('active')) {
            show($tabs.first());
            return;
        }
    }

    function show ($tab) {
        let dataPath = $tab.data('path');
        
        $tab.tab('show');
        
        if (dataPath) {
            history.replaceState({}, 'Tab Change', dataPath);
        }
    }

    function selectOption (e) {
        e.preventDefault();

        let selectedOption = $(e.target).find('option:selected')[0];
        let $tab = $(selectedOption.dataset.tab);

        show($tab);
    }  

})(jQuery);

export default Tabs;