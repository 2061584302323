import Viewport from './_viewport';
import TopNav from './_topnav';
import Modal from './_modal';
import Carousel from './_carousel';
import Tabs from './_tabs';
import Truncate from './_truncate-text';

/**
 * Module Initialisers
 */
$(function() {

    // Plugins
    $('.js-match-height').matchHeight();

    // Modules
    Viewport.init();
    TopNav.init();
    Modal.init();
    Carousel.init()
    Tabs.init();
    Truncate.init();
});
