/**
 * --------------------------------------------------------------------------
 * Emergency Management: viewport.js
 * --------------------------------------------------------------------------
 */

const Viewport = (($) => {
    
    // create breakpoints definition
    let currentBreakPoint = null;
    const breakpoints = [
        { name: 'mobile' },
        { name: 'tablet', min: '740px' },
        { name: 'desktop', min: '980px' },
        { name: 'wide', min: '1380px' }
    ];

    /**
     * Public Methods
     */
    return {
        init,
        breakpoints,
        getBreakpointMediaString,
        getCurrentBreakPoint
    };

    
    /*-------------------------------*/

    function init () {
        _registerBreakpoints();
    }
    
    function _mediaStringBuilder (i, breakpoints) {
        
        // set return object
        var mediaString = "screen ";
        
        // write regular query
        if (breakpoints[i].min) {
            mediaString += "and (min-width: " + breakpoints[i].min + ") ";
        }

        return mediaString;
            
    } 

    function _registerBreakpoints () {
        breakpoints.forEach(function(el, i){
            
            var mediaString = _mediaStringBuilder(i, breakpoints);
            
            // Record current breakpoint when a media query matches.
            enquire.register(mediaString, {
                match: function(){
                    currentBreakPoint = breakpoints[i].name;
                },
                unmatch: function(){
                    currentBreakPoint = breakpoints[i - 1].name;
                }            
            });    
        });
    }

    function getBreakpointMediaString(index) {
        return _mediaStringBuilder(index, breakpoints);
    }

    /**
     * Returns a string of the current breakpoint 
     * from list of configured breakpoints. 
     * 
     * @returns string 
     */
    function getCurrentBreakPoint () {
        return currentBreakPoint;
    }
    
})(jQuery);

export default Viewport;