import Config from './_config';
import Viewport from './_viewport';

/**
 * --------------------------------------------------------------------------
 * Emergency Management: _topnav.js
 * --------------------------------------------------------------------------
 */

const TopNav = (($, Viewport) => {

    const $navLinks = $('.c-topnav__btn ');
    const $searchToggle = $('.c-topnav__btn--search');
    const options = {
        byRow: true,
        property: 'height',
        target: null,
        remove: false
    };

    return {
        init
    };

    /*-----------------*/

    function init () {
        registerEventHandlers();
        registerBreakpointChange();
    }

    function registerBreakpointChange() {
        enquire.register("screen and (max-width: 1032px)", {
            match: function () {
                $navLinks.matchHeight(options);
            },
            unmatch: function () {
                $navLinks.matchHeight({ remove: true });
            }
        });
    }

    function registerEventHandlers () {
        focusSearchInputOnOpen();
    }
    
    function focusSearchInputOnOpen() {
        const $searchContainer = $('#collapseSearch');
        $searchContainer.on('shown.bs.collapse', () => {
            const $searchInput = $searchContainer.find('input');
            $searchInput.focus();
        });
    }

})(jQuery, Viewport);

export default TopNav;

